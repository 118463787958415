import React, { useState } from 'react';
import axios from 'axios';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';

function Home() {
  const [input, setInput] = useState('');
  const [link, setLink] = useState('');

  const handleSubmit = async () => {
    const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/encrypt`, {
      text: input,
    });

    console.log('response.data.link: ', response.data.link);
    setLink(response.data.link);
    setInput('');
  };

  return (
    <Container maxWidth="lg">
      <TextField
        multiline
        minRows={20}
        variant="outlined"
        placeholder="Enter text"
        fullWidth
        value={input}
        onChange={(e) => setInput(e.target.value)}
      />
      <Button variant="contained" color="primary" onClick={handleSubmit} fullWidth>
        Submit
      </Button>
      <a href={link} target="_blank" rel="noopener noreferrer">
        {link}
      </a>
    </Container>
  );
}

export default Home;
