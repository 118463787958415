import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { xonokai } from 'react-syntax-highlighter/dist/esm/styles/prism';

function RetrieveText() {
  const [code, setCode] = useState('');
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/retrieve/${id}`);
        console.log('response.data: ', response.data);
        setCode(response.data);
      } catch (error) {
        console.error('Error retrieving code', error);
      }
    };
    fetchData();
  }, [id]);

  return (
    <div>
      <SyntaxHighlighter language="javascript" style={xonokai}>
        {code}
      </SyntaxHighlighter>
    </div>
  );
}

export default RetrieveText;
